exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aboutus-index-tsx": () => import("./../../../src/pages/aboutus/index.tsx" /* webpackChunkName: "component---src-pages-aboutus-index-tsx" */),
  "component---src-pages-asbuilt-index-tsx": () => import("./../../../src/pages/asbuilt/index.tsx" /* webpackChunkName: "component---src-pages-asbuilt-index-tsx" */),
  "component---src-pages-blogs-detail-page-index-tsx": () => import("./../../../src/pages/blogsDetailPage/index.tsx" /* webpackChunkName: "component---src-pages-blogs-detail-page-index-tsx" */),
  "component---src-pages-blogs-index-tsx": () => import("./../../../src/pages/blogs/index.tsx" /* webpackChunkName: "component---src-pages-blogs-index-tsx" */),
  "component---src-pages-costcalculator-index-tsx": () => import("./../../../src/pages/costcalculator/index.tsx" /* webpackChunkName: "component---src-pages-costcalculator-index-tsx" */),
  "component---src-pages-faqs-index-tsx": () => import("./../../../src/pages/faqs/index.tsx" /* webpackChunkName: "component---src-pages-faqs-index-tsx" */),
  "component---src-pages-form-index-tsx": () => import("./../../../src/pages/form/index.tsx" /* webpackChunkName: "component---src-pages-form-index-tsx" */),
  "component---src-pages-home-interior-price-calculator-index-tsx": () => import("./../../../src/pages/home-interior-price-calculator/index.tsx" /* webpackChunkName: "component---src-pages-home-interior-price-calculator-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-interiors-index-tsx": () => import("./../../../src/pages/interiors/index.tsx" /* webpackChunkName: "component---src-pages-interiors-index-tsx" */),
  "component---src-pages-office-interior-landing-page-index-tsx": () => import("./../../../src/pages/office-interior-landing-page/index.tsx" /* webpackChunkName: "component---src-pages-office-interior-landing-page-index-tsx" */),
  "component---src-pages-ourstory-index-tsx": () => import("./../../../src/pages/ourstory/index.tsx" /* webpackChunkName: "component---src-pages-ourstory-index-tsx" */),
  "component---src-pages-portfolio-index-tsx": () => import("./../../../src/pages/portfolio/index.tsx" /* webpackChunkName: "component---src-pages-portfolio-index-tsx" */),
  "component---src-pages-portfoliodetail-index-tsx": () => import("./../../../src/pages/portfoliodetail/index.tsx" /* webpackChunkName: "component---src-pages-portfoliodetail-index-tsx" */)
}

